@import "./assets/styles/scss/variables";
@import "./assets/styles/scss/mixins";
@import "./assets/styles/scss/fonts";
@import "./assets/styles/scss/extendsStart";
@import "./assets/styles/scss/general";
@import "./assets/styles/scss/extendsEnd";

#root {
  height: 100%;
}

.disable_events {
  pointer-events: none;
}