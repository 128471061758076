@import '../../../assets/styles/scss/variables';

main {
  height: 100%;
}

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 25px;
  background-color: transparent;
  color: $white;
}