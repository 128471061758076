%standardList {
	@include standardList();
}

%standardRowList {
	@include standardRowList();
}

%coverLayer {
	@include coverLayer();
}

%fixedCover {
	@include fixCoverLayer;
}

%verticalRotate {
	@include transStyle(scaleX(-1));
}

%horizontalRotate {
	@include transStyle(scaleY(-1));
}

%centeredImage {
	picture >img,
	> img {
		@extend %coverLayer;
		object-fit: cover;
	}
}

%coverVideo {
	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

%square {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 100%;
	}
}

%almostSquare {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 75%;
	}
}

%lessWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 62.5%;
	}
}

%standardWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 56.25%;
	}
}

%halfWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 50%;
	}
}

%superWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 25%;
	}
}

%standardNarrow {
	@include overHidden();
	@extend %centeredImage;
	&:before {
		display: block;
		content: "";
		padding-bottom: 177.78%;
	}
}

%lessNarrow {
	@include overHidden();
	@extend %centeredImage;
	&:before {
		display: block;
		content: "";
		padding-bottom: 133.33%;
	}
}

%iconElement {
	&:before,
	&:after {
		font-family: 'icon' !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

%noBlink {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

%centerBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

%centerInlineBlock {
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	justify-content: center;
	text-align: center;
}

%linkTypeBtn {
	border: none;
	padding: 0;
	background: transparent;
}

%middleText {
	font-size: 160%;
	line-height: 19px;
}

%standardText {
	font-size: 140%;
	line-height: 20px;
}

%smallText {
	font-size: 120%;
	line-height: 15px;
}

%standardBtn {
	border: none;
	height: 40px;
	@extend %standardText;
	@extend %centerInlineBlock;
	@include gridPadding;
}

%primaryBtn {
	@extend %standardBtn;
	background: $siteColor;
	color: $textColor;
	@include loading($textColor);
}

%secondaryBtn {
	@extend %standardBtn;
	outline: 1px solid $siteColor !important;
	color: $siteColor;
	background: transparent;
	@include loading($siteColor);
}

%menuSize {
	font-size: 160%;
	line-height: 1.6em;
}

%sectionTitle {
	font-size: 1120%;
	line-height: 1.3em;
	font-family: $titleFont;
	font-weight: 400;
	margin: 0;
	letter-spacing: -0.005em;
	@include mediaRange($size1280, $size1600) {
		font-size: 920%;
	}
	@include mediaRange($size960, $size1280) {
		font-size: 720%;
	}
	@include mediaRange($size576, $size960) {
		font-size: 560%;
	}
	@include mediaTo($size576) {
		font-size: 400%;
	}
}

%sectionsubTitle {
	font-size: 800%;
	line-height: 1.2em;
	font-family: $titleFont;
	font-weight: 400;
	margin: 0;
	letter-spacing: -0.005em;
	@include mediaRange($size1280, $size1600) {
		font-size: 640%;
	}
	@include mediaRange($size960, $size1280) {
		font-size: 480%;
	}
	@include mediaRange($size576, $size960) {
		font-size: 400%;
	}
	@include mediaTo($size576) {
		font-size: 300%;
	}
}