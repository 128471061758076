html {
    height: 100%;
    width: 100%;
    font-size: 10px;
    line-height: 1.4286em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 100%;
    color: $textColor;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    min-height: 100%;
    font-family: $regular;
    background: $secondColor;
    @include popupBody($black33, 0.5, 0.75s);
}

.page_screen {
    @extend %coverLayer;
    object-fit: cover;
    z-index: -1;
}

@keyframes ticker {
    to {
        margin-left: -33.33%;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

* {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

input,
select,
textarea,
button,
a,
.slick-slide {
   outline: none !important;
}

a,
a:focus,
a:hover,
button:hover {
    text-decoration: none;
}

a {
    color: $siteColor;
}

button {
    cursor: pointer;
    display: block;
}

img	{
    max-height: none;
    border: none;
}

input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input,
textarea,
button {
    font-family: inherit;
    font-size: 100%;
}

.page_row {
    display: flex;
    flex-wrap: wrap;
    @include rowMargin;
    .cb {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.page_container {
    width: 100%;
    max-width: $containerWidth + 2*$containerLargePadding;
    margin: 0 auto;
    @include containerPadding;
}

.individual_hint,
.drop_list,
.drop_block {
    display: none;
}

.error_hint {
    display: block;
    @extend %all300;
    overflow: hidden;
    color: $error;
    font-size: 120%;
    line-height: 1.6em;
    &:before {
        display: block;
        content: "";
        height: 10px;
    }
}

.has-error {
    .error_hint,
    ~ .error_hint {
        max-height: 60px;
   }
}

.field_block {
    position: relative;
    @include placeholder {
        color: $white;
    }
    input,
    textarea {
        width: 100%;

   }
    textarea {
        resize: none;
        display: block;
   }
}
.cb {
    display: block;
    clear: both;
}

.page_title_block {
    @include overHidden;
}

.section_title {
    @extend %sectionTitle;
}

.section_subtitle {
    @extend %sectionsubTitle;
}

.primary_btn {
    @extend %primaryBtn;
}

.standard_text {
	@extend %standardText;
}

.bold {
    font-family: $medium;
}




.logout_popup {
    @include popupBlock;
    @include popupFade(0.75s ease-out);
    .popup_inner {
        @include popupInner(24px $containerMobPadding);
    }
    .popup_container {
        @include popupContainer($secondColor, 708px, 154px 48px);
        border-radius: 48px;
        text-align: center;
    }
    .popup_close.icon_close {
        position: absolute;
        z-index: 10;
        top: 28px;
        right: 48px;
        @extend %linkTypeBtn;
        display: flex;
        align-items: center;
        font-size: 150%;
        line-height: 18px;
        color: $white;
        @include siteColorHover;
        &:before {
            order: 2;
            font-size: 2.4rem;
            margin-left: 8px;
            flex: 0 0 12px;
            max-width: 12px;
            height: 12px;
            @extend %centerBlock;
        }
    }

    .popup_title {
        font-size: 320%;
        line-height: 39px;
        font-family: $medium;
        padding-bottom: 8px;
    }

    .standard_btn,
    .secondary_btn {
        margin-top: 48px;
        width: 460px;
        max-width: 100%;
    }

    @include mediaRange($size576, $size960) {
        .popup_container {
            max-width: 560px;
            padding: 124px 32px;
            border-radius: 32px;
        }
        .popup_close.icon_close {
            right: 24px;
            top: 24px;
        }


    }

    @include mediaTo($size576) {
        .popup_container {
            padding: 48px 16px;
            border-radius: 24px;
        }
        .popup_close.icon_close {
            right: 16px;
            top: 16px;
        }

        .popup_title {
            font-size: 240%;
            line-height: 32px;
        }


    }
}

.standard_btn {
    padding: 0 30px;
    height: 119px;
    width: 463px;
    @extend %centerInlineBlock;
    background: transparent url('../../images/btn_bg.png') no-repeat center;
    background-size: 100% 100%;
    font-size: 180%;
    line-height: 22px;
    font-family: $medium;
    color: $secondColor;
    border-radius: 39%;
    position: relative;
    border: none;
    @include loading(inherit);
    @include opacityHover;

    span {
        @include zIndex(2);
        @include transStyle(rotate(-3deg));
    }
}

.secondary_btn {
    padding: 0 30px;
    height: 112px;
    @extend %centerInlineBlock;
    color: inherit;
    margin-top: 48px;
    background: transparent;
    font-size: 180%;
    line-height: 22px;
    font-family: $medium;
    border: 9px dashed;
    border-radius: 50%;
    position: relative;
    @include loading(inherit);
    @include transStyle(rotate(-3deg));
    &:before {
        @include psevdoAbs;
        border-radius: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $secondColor;
        margin: -6px;
    }
    span {
        @include zIndex(2);
    }
    @include opacityHover;
}

.ticker {
    min-height: 64px;
    width: 100%;
    @include overHidden;
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    white-space: nowrap;


    * {
        @extend %noBlink;
    }
    ul {
        @extend %standardList;
        font-size: 0;
        display: inline-block;
        vertical-align: top;
        align-items: center;
        position: relative;
        z-index: 2;
    }
    li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-size: 1.8rem;
        line-height: 22px;
        @include animStyle(none);
        @extend %noBlink;
        animation: none;
        &:first-child {
            animation: ticker 15s linear infinite;
            animation-delay: 1s;
        }
    }
    .icon_block {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        text-align: center;
        padding: 0 10px;
        img {
            display: inline-block;
            vertical-align: top;
            max-width: 100%;
            max-height: 36px;
            object-fit: contain;
        }
    }
}

.page_messages{
    position: fixed;
    overflow: hidden;
    left: 0;
    width: 100%;
    bottom: 32px;
    @include containerPadding;
    z-index: 1000;
    .message_block{
        display: flex;
        align-items: center;
        border-radius: 60px;
        padding: 20px 48px;
        width: 1024px;
        max-width: 100%;
        margin: 40px auto -300px;
        position: relative;
        animation: showMessage 5s forwards;

        &::before{
            display: block;
            @extend %iconElement;
            font-size: 4rem;
            margin-right: 24px;
            position: relative;
            z-index: 2;
        }
        .message_text {
            flex: 1;
            width: 40%;
            position: relative;
            z-index: 2;
            display: block;
        }
        .message_title {
            display: block;
            font-size: 200%;
            line-height: 1.6em;
            color: $secondColor;
        }
        .message_desc {
            display: block;
            color: $black33;
            margin-top: 4px;
            @extend %standardText;
        }
        .message_remove {
            display: block;
            cursor: pointer;
            color: $secondColor;
            @include removeHover;
            position: relative;
            z-index: 2;
            margin-left: 24px;
            font-size: 320%;
        }
        &:after {
            @extend %coverLayer;
            content: "";
            opacity: 0.15;
        }
    }
    .success_message{
        background: $successBg;
        &:before{
            color: $success;
        }
    }

    .error_message{
        background: $errorBg;
        &::before{
            color: $error;
        }
    }
    @include mediaRange($size768, $size960) {
        .message_block {
            padding: 16px 32px;
            margin-top: 24px;
            &:before {
                font-size: 3.6rem;
                margin-right: 16px;
            }
            .message_title {
                font-size: 180%;
            }
        }
    }
    @include mediaTo($size768) {
        .message_block {
            padding: 12px 16px;
            margin-top: 16px;
            &:before {
                font-size: 3.2rem;
                margin-right: 12px;
            }
            .message_title {
                font-size: 160%;
            }
            .message_remove {
                margin-left: 12px;
            }
        }

    }

}

@keyframes showMessage {
    0% {
        margin-bottom: -300px;
    }
    14% {
        margin-bottom: 0;
    }
    86% {
        margin-bottom: 0;
    }
    100% {
        margin-bottom: -300px;
    }
}


@keyframes loadAnimation {
    9% {
        box-shadow: none;
    }
    10% {
        box-shadow: -8px 0 0;
    }
    35% {
        box-shadow: -8px 0 0;
    }
    35.1% {
        box-shadow: -8px 0 0, 8px 0 0;
    }
    60% {
        box-shadow: -8px 0 0, 8px 0 0;
    }
    60.1% {
        box-shadow: -8px 0 0, 8px 0 0, 24px 0 0;
    }
    85% {
        box-shadow: -8px 0 0, 8px 0 0, 24px 0 0;
    }
    85.1% {
        box-shadow: none
    }
    100% {
        box-shadow: none
    }
}


